<template>
  <div class="h-100-vh">
    <div class="  form-rounded-inputs">
      <div class="row justify-content-end ">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <div class="input-group">
              <select name="" class="custom-select" v-model="notificationsPagination.read"
                @change="fnFirstPagination()">
                <option :value="null" v-text="$t('general.form.all')"> </option>
                <option :value="false" v-text="$t('dashboard.notifications.unread')"> </option>
                <option :value="true" v-text="$t('dashboard.notifications.read')"> </option>
              </select>
              <div class="input-group-append">
                <label class="input-group-text bg-success">
                  <i class="fa fa-bell"></i>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="(notificationsList.length > 0) && !loading.data">
        <table class="table table-striped table-hover ecart-table" v-show="notificationsList.length > 0 ">
          <thead>
            <tr>
              <th v-text="$t('dashboard.notifications.title')">
              </th>
            </tr>
          </thead>
          <transition-group tag="tbody" class="list-container" :duration="{ leave: 1000 }"
            enter-active-class="animated bounceIn" leave-active-class="animated fadeOutRight">
            <tr v-for=" (notification) in notificationsList" v-bind:key="notification.id" class="animated fadeIn">
              <td>
                <Notification v-bind:notification="notification" />
              </td>
            </tr>
          </transition-group>
        </table>

        <div
          v-if="notificationsPagination.total > 0 && ( (notificationsPagination.total / notificationsPagination.limit) > 1 ) ">

          <b-pagination v-model="notificationsPagination.page" :total-rows="notificationsPagination.total"
            :per-page="notificationsPagination.limit" @input="fnApiGetNotifications" align="fill"
            :first-text="$t('general.button.first')" :prev-text="$t('general.button.previous')"
            :next-text="$t('general.button.next')" :last-text="$t('general.button.last')" class="d-none d-md-flex"
            v-if=" notificationsPagination.total > 0 && ( (notificationsPagination.total / notificationsPagination.limit) > 1 ) ">
          </b-pagination>

          <!-- <b-pagination v-model="pagination.page" :total-rows="pagination.total" :per-page="pagination.limit"
            @input="fnApiGetStores" align="fill" class="d-none d-md-flex" :first-text="$t('general.button.first')"
            :prev-text="$t('general.button.previous')" :next-text="$t('general.button.next')"
            :last-text="$t('general.button.last')">
          </b-pagination> -->

          <b-pagination v-model="notificationsPagination.page" :total-rows="notificationsPagination.total"
            :per-page="notificationsPagination.limit" @input="fnApiGetNotifications" align="fill" class="d-md-none"
            v-if=" notificationsPagination.total > 0 && ( (notificationsPagination.total / notificationsPagination.limit) > 1 ) ">
          </b-pagination>

          <!-- <b-pagination v-model="pagination.page" :total-rows="pagination.total" :per-page="pagination.limit"
            @input="fnApiGetStores" align="fill" class="d-md-none"
            v-if="pagination.total > 0 && ( (pagination.total / pagination.limit) > 1 ) ">
          </b-pagination> -->
        </div>

        <!-- <b-pagination v-model="notificationsPagination.page" :total-rows="notificationsPagination.total"
            :per-page="notificationsPagination.limit" @input="fnApiGetNotifications" align="fill"
            :first-text="$t('general.button.first')" :prev-text="$t('general.button.previous')"
            :next-text="$t('general.button.next')" :last-text="$t('general.button.last')" class="d-none d-md-flex" 
            v-if=" notificationsPagination.total > 0 && ( (notificationsPagination.total / notificationsPagination.limit) > 1 ) ">
          </b-pagination> -->
      </div>
      <no-data v-if=" notificationsList.length == 0 || loading.data " :isLoading="loading.data"
        :dataMessage="$t('noDataMessages.notificationsList')" :showBtn="false"> </no-data>
    </div>
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
  } from "vuex";
  import Notification from "@/components/Notification";
  export default {
    components: {
      Notification
    },
    data() {
      return {}
    },
    computed: {
      ...mapState('EcartNotifications', ['loading', 'notificationsList', 'showNotifications', 'notificationsCount',
        'notificationsPagination'
      ]),
      ...mapState('EcartProducts', ['productsList']),
      ...mapState({
        menuList: state => state.menuList,
        user: state => state.session.user,
      }),
    },
    watch: {
      async notificationsList() {
        let tempList = [];
        this.notificationsList.map(notificaiton => {
          if (notificaiton.event_type == 'question') {
            tempList.push(notificaiton.reference_id);
          }
        })
        await this.fnApiGetProductsInformation(tempList);
        this.fnSetProducts();
      },
      productsList() {
        this.fnSetProducts();
      }
    },
    methods: {
      // ...mapMutations('EcartNotifications', ['']),
      ...mapActions('EcartProducts', ['fnApiGetProductsInformation']),
      ...mapActions('EcartNotifications', ['fnResetPagination', 'fnFirstPagination', 'fnApiGetNotifications']),
      fnSetProducts() {
        this.notificationsList.map(notificaiton => {
          if ((notificaiton.product == null) || (notificaiton.product == undefined)) {
            let tempProduct = this.productsList.find(product => (product.id == notificaiton.reference_id));
            if (tempProduct != undefined) {
              notificaiton.product = tempProduct;
            }
          }
        })
      }
    },
    mounted() {
      this.fnResetPagination();
    }
  }
</script>